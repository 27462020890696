<template>
  <div class="page page-home">
    <div class="content-wrapper">
      <div class="page-content">
        <div class="module-1">
          <el-row><img v-for="(v,i) in services" :key="i" :src="v.icon" /></el-row>
          <h3>开放服务</h3>
          <p>提供专业权威、优质高效、丰富灵活的K12教育资源服务，支持多种资源组合形式，满足多场景使用。</p>
        </div>
        <img class="module-2" :src="servicesRhomb" />
        <div class="module-3">
          <div v-for="(v,i) in resources" :key="i" :class="['box', `box-${i + 1}`]">
            <div><img :src="v.icon" /></div>
            <h4>{{v.label}}</h4>
            <p>{{v.content}}</p>
          </div>
        </div>
        <div class="module-4">
          <el-row><img v-for="(v,i) in resources" :key="i" :src="v.iconWhite" /></el-row>
          <h3><span class="text-warning">资源</span><sub>类型</sub></h3>
          <p>提供类型丰富、种类齐全的资源内容，包括课件、教学设计、微课、动画、音频、图片等，满足多样化的教学和学习需求。</p>
        </div>
        <img class="module-5" :src="openingProcess" />
      </div>
    </div>
  </div>
</template>

<script>
import icon_tbzy from '../../assets/images/home/tbzy.png'
import iconColour_tbzy from '../../assets/images/home/tbzy-colour.png'
import iconWhite_tbzy from '../../assets/images/home/tbzy-white.png'
import icon_zkzy from '../../assets/images/home/zkzy.png'
import iconColour_zkzy from '../../assets/images/home/zkzy-colour.png'
import iconWhite_zkzy from '../../assets/images/home/zkzy-white.png'
import icon_gkzy from '../../assets/images/home/gkzy.png'
import iconColour_gkzy from '../../assets/images/home/gkzy-colour.png'
import iconWhite_gkzy from '../../assets/images/home/gkzy-white.png'
import icon_ktzy from '../../assets/images/home/ktzy.png'
import iconColour_ktzy from '../../assets/images/home/ktzy-colour.png'
import iconWhite_ktzy from '../../assets/images/home/ktzy-white.png'
import icon_kj from '../../assets/images/home/kj.png'
import icon_jxsj from '../../assets/images/home/jxsj.png'
import icon_jxsp from '../../assets/images/home/jxsp.png'
import icon_sc from '../../assets/images/home/sc.png'
import iconWhite_kj from '../../assets/images/home/kj-white.png'
import iconWhite_jxsj from '../../assets/images/home/jxsj-white.png'
import iconWhite_jxsp from '../../assets/images/home/jxsp-white.png'
import iconWhite_sc from '../../assets/images/home/sc-white.png'
import servicesRhomb from '../../assets/images/home/services-rhomb.png'
import openingProcess from '../../assets/images/home/opening-process.png'
export default {
  name: 'page-home',
  data () {
    return {
      services: [
        {label: '同步资源', icon: icon_tbzy, iconColour: iconColour_tbzy, iconWhite: iconWhite_tbzy},
        {label: '中考资源', icon: icon_zkzy, iconColour: iconColour_zkzy, iconWhite: iconWhite_zkzy},
        {label: '高考资源', icon: icon_gkzy, iconColour: iconColour_gkzy, iconWhite: iconWhite_gkzy},
        {label: '考题资源', icon: icon_ktzy, iconColour: iconColour_ktzy, iconWhite: iconWhite_ktzy}
      ],
      resources: [
        {label: '课件', icon: icon_kj, iconWhite: iconWhite_kj, content: '同步课件ppt/pptx'},
        {label: '教学设计', icon: icon_jxsj, iconWhite: iconWhite_jxsj, content: '同步授课教学设计doc/docx'},
        {label: '教学视频', icon: icon_jxsp, iconWhite: iconWhite_jxsp, content: '教学实录、示范课、微课mp4'},
        {label: '素材', icon: icon_sc, iconWhite: iconWhite_sc, content: '文档素材、图片素材、音频素材、视频素材doc/docx/jpg/png/mp3/mp4'}
      ],
      servicesRhomb,
      openingProcess
    }
  }
}
</script>

<style lang="less" scoped>
.page-home {
  .content-wrapper {
    height: 2621px;
    background:
      url("../../assets/images/home/bg01.png") no-repeat 0 0,
      url("../../assets/images/home/bg02.png") no-repeat 0 440px,
      url("../../assets/images/home/bg03.png") no-repeat 0 1252px,
      url("../../assets/images/home/bg04.png") no-repeat 0 bottom
    ;
    background-size: 100% auto;
    .page-content {
      max-width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: relative;
    }
    .module-1 {
      position: absolute;
      left: 20px;
      top: 650px;
      width: 45%;
      img { width: 32px; height: 32px; margin-right: 10px; }
      h3 { font-size: 32px; margin-bottom: 10px; }
      p { font-size: 18px; color: #657180; }
    }
    .module-2 {
      position: absolute;
      right: 20px;
      top: 620px;
      width: 45%;
    }
    .module-3 {
      .box {
        position: absolute;
        border-radius: 12px;
        text-align: center;
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {width: 48px; height: 48px;}
        h4 {font-size: 24px; line-height: 2; color: #333c;}
        p {color: #777;}
        &:hover {
          box-shadow: 0 0 15px rgba(0, 0, 0, .2);
        }
      }
      .box-1 {
        left: 310px;
        top: 1225px;
        z-index: 2;
        width: 250px;
        height: 160px;
        background-image: linear-gradient(to right, #ffe751, #ffca28);
      }
      .box-2 {
        left: 430px;
        top: 1350px;
        width: 305px;
        height: 210px;
        background-color: #fff;
      }
      .box-3 {
        left: 20px;
        top: 1515px;
        width: 320px;
        height: 210px;
        background-color: #fff;
      }
      .box-4 {
        left: 310px;
        top: 1660px;
        z-index: 2;
        width: 390px;
        height: 240px;
        background-image: linear-gradient(to right, #24e8ac, #03bfc5);
      }
    }
    .module-4 {
      position: absolute;
      right: 20px;
      top: 1425px;
      width: 29%;
      img { width: 32px; height: 32px; margin-right: 10px; }
      h3 {
        font-size: 32px; margin-bottom: 10px; letter-spacing: .15em;
        sub { font-size: .6em; color: #fff; text-shadow: 0 0 2px #666; }
      }
      p { font-size: 18px; color: #fff; text-shadow: 0 0 2px #666; }
    }
    .module-5 {
      position: absolute;
      left: 0;
      bottom: 70px;
      width: 100%;
    }
  }
}
</style>
